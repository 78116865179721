<template>
    <v-row justify="center" class="py-5" align-content="center" style="height: 100%;">
        <v-col cols="12" sm="6">
            <v-card>
                <v-toolbar dense flat color="#3F51B5" dark>
                    <v-toolbar-title>Sign up</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-progress-linear color="#3F51B5" height="10" :value="progress" striped class="mb-6">
                </v-progress-linear>
                <template v-if="step === 'start' && !redirect">
                    <v-card-text>
                        <p>Getting started is quick and easy. What is your email address?</p>
                    </v-card-text>
                    <v-form @submit.prevent="signup" onSubmit="return false;" @keyup.enter.native.prevent="signup" class="mx-4 pb-6">
                        <v-text-field v-model="username" ref="usernameInput" dense solo color="#3F51B5" hint="This will be your username. You can change it later." placeholder="Email" :error-messages="inputError">
                            <template #prepend-inner>
                                <font-awesome-icon :icon="['fas', 'envelope']" fixed-width/>
                            </template>
                        </v-text-field>
                        <v-row no-gutters justify="center">
                        <v-btn @click="signup" elevation="4" color="#3F51B5" class="white--text mt-4">Continue</v-btn>
                        </v-row>
                    </v-form>
                </template>
                <template v-if="['start', 'verify_email'].includes(step) && redirect">
                    <v-card-text>
                        <p><a :href="redirect">Continue to email verification</a></p>
                    </v-card-text>
                </template>
                <template v-if="step === 'verify_email' && !isViewReady">
                    <v-card-text>
                        <p class="mb-0">Please wait...</p>
                    </v-card-text>
                </template>
                <template v-if="step === 'verify_email' && isViewReady && registered">
                    <v-text-field v-model="username" dense solo flat color="#3F51B5" placeholder="Email" readonly>
                        <template #prepend-inner>
                            <font-awesome-icon :icon="['fas', 'envelope']" fixed-width/> <!-- style="font-size: 20px;" -->
                        </template>
                    </v-text-field>
                    <v-card-text>
                        <!-- NOTE: since the user verified the email address, we can inform them of the status -->
                        <p>
                            This email address has already been registered.
                        </p>
                        <p><router-link :to="{ name: 'login', query: { email: username } }">Sign in to your enterprise</router-link></p>
                        <p><a @click.prevent="switchEnterprise" href="#">Use a different email address</a></p>
                    </v-card-text>
                </template>
                <template v-if="step === 'verify_email' && isViewReady && !registered">
                    <v-card-text>
                        <p class="mb-0">Please wait...</p>
                    </v-card-text>
                </template>
                <template v-if="step === 'display_name'">
                    <v-card-text>
                        <p>What is your name?</p>
                        <p class="text-caption">We will use this name to address you on our website, in emails, enterprise statements, and any other communications.</p>
                    </v-card-text>
                    <v-form @submit.prevent="signup" onSubmit="return false;" @keyup.enter.native.prevent="signup" class="mx-4 pb-6">
                        <v-text-field v-model="displayName" ref="displayNameInput" dense solo color="#3F51B5" hint="Your full name, first name, or a nickname. You can change it later." placeholder="Name" :error-messages="inputError">
                            <template #prepend-inner>
                                <font-awesome-icon :icon="['fas', 'user']" fixed-width/>
                            </template>
                        </v-text-field>
                        <v-row no-gutters justify="center">
                        <v-btn @click="signup" elevation="4" color="#3F51B5" class="white--text mt-4">Continue</v-btn>
                        </v-row>
                    </v-form>
                </template>
                <template v-if="step === 'notice' && isViewReady">
                    <v-card-text>
                        <p>Almost there!</p>
                        <p>We want to take this opportunity to thank you for joining us. We are always interested in making Cryptium even better. If you encounter any issues or want to share your thoughts, please use the question mark icon at the top of the page to get in touch with us.</p>
                        <p>The next steps are to set up your profile and enterprise so you can get started.</p>
                        <!-- TODO: change "get started" with a specific phrase related to the product the user selected when signing up; if none selected then use "get started" as a default. for example, so you can... "upload and share documents", or so you can ... "send secure messages", etc. -->
                        <v-btn color="indigo white--text" @click="setup" class="mt-4">Continue</v-btn>
                    </v-card-text>
                </template>
            </v-card>
            <template v-if="step === 'start' && !redirect">
                <p class="mt-6 grey--text text--darken-2 text-center">Already have an enterprise? <router-link :to="{ name: 'login' }">Sign in</router-link></p>
            </template>
            <p class="mb-15"></p>
            <v-alert type="error" v-if="serverError">
                An error occurred while processing your request. Please try again or contact customer support.
            </v-alert>
            <v-alert type="error" v-if="requestError">
                We could not send a verification email. Please try again. If the problem continues, try with a different email address or contact customer support.
            </v-alert>
            <v-alert type="error" v-if="forbiddenError">
                The link is expired or invalid. Check that the email you entered is correct and try again.
            </v-alert>
        </v-col>
    </v-row>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
</style>

<script>
// import { toText } from '@libertyio/time-util-js';
import { mapState } from 'vuex';
import { isValidEmail, isValidProductLookupKey } from '@/sdk/input';
// import BrandList from '@/components/BrandList.vue';

// constants
const IA_SIGNUP = 'signup';

export default {
    components: {
        // BrandList,
    },
    data: () => ({
        isViewReady: false,
        steps: ['start', 'verify_email', 'display_name', 'notice'],
        step: 'start',
        username: '',
        displayName: '',
        product: null,
        submitTimestamp: null,
        redirect: null,
        // older ones, check if we're using
        interactionId: null,
        verificationExpires: null,
        serverError: false,
        serverErrorTimeout: null,
        requestError: false,
        requestErrorTimeout: null,
        inputError: null,
        inputErrorTimeout: null,
        forbiddenError: null,
        forbiddenErrorTimeout: null,
        registered: false,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            focus: (state) => state.focus,
        }),
        // mainWebsiteURL() {
        //     return process.env.VUE_APP_MAIN_WEBSITE_URL ?? 'https://brandprofile.org';
        // },
        progress() {
            if (this.steps.length === 0) {
                return 0;
            }
            const idx = this.steps.findIndex((value) => value === this.step);
            return Math.ceil(((idx + 1) * 100) / this.steps.length);
        },
    },
    watch: {
        focus() {
            if (this.step === 'start') {
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('usernameInput'); }, 1);
                });
            }
            if (this.step === 'display_name') {
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('displayNameInput'); }, 1);
                });
            }
        },
        $route(newValue) {
            if (newValue.query.step !== this.step) {
                this.step = newValue.query.step;
                if (newValue.query.step === 'start') {
                    this.$nextTick(() => {
                        setTimeout(() => { this.activate('usernameInput'); }, 1);
                    });
                }
            }
        },
    },
    methods: {
        resetErrors() {
            this.serverError = false;
            if (this.serverErrorTimeout) {
                clearTimeout(this.serverErrorTimeout);
                this.serverErrorTimeout = null;
            }
            this.requestError = false;
            if (this.requestErrorTimeout) {
                clearTimeout(this.requestErrorTimeout);
                this.requestErrorTimeout = null;
            }
            this.inputError = null;
            if (this.inputErrorTimeout) {
                clearTimeout(this.inputErrorTimeout);
                this.inputErrorTimeout = null;
            }
            this.forbiddenError = false;
            if (this.forbiddenErrorTimeout) {
                clearTimeout(this.forbiddenErrorTimeout);
                this.forbiddenErrorTimeout = null;
            }
            this.redirect = null;
            this.verificationExpires = null;
        },
        async signup() {
            if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitTimestamp = Date.now();
            try {
                this.resetErrors();
                if (typeof this.username !== 'string' || this.username.trim().length === 0 || !isValidEmail(this.username)) {
                    this.inputError = 'Please enter an email address';
                    this.inputErrorTimeout = setTimeout(() => { this.inputError = null; }, 15000); // clear message in 15 seconds
                    return;
                }
                this.$store.commit('loading', { signup: true });
                const request = {
                    email: this.username,
                    display_name: this.displayName,
                    interactionId: this.interactionId, // null on first request, possibly a value after email verification (but not necessarily)
                    product: this.product, // may have a value if user arrived here by selecting a something on the pricing page
                };
                console.log(`request ${JSON.stringify(request)}`);
                const response = await this.$client.main().authn.signup(request);
                console.log(`Signup.vue: response ${JSON.stringify(response)}`);
                if (response?.status) {
                    switch (response.status) {
                    case 'redirect':
                        if (response.redirect) {
                            // show a link after 2 seconds in case auto-redirect fails
                            setTimeout(() => {
                                this.redirect = response.redirect;
                            }, 2000);
                            // use replace so that when user taps 'back' button from there, they won't
                            // end up being redirected again to where they just wanted to come back from
                            if (typeof window.location.replace === 'function') {
                                window.location.replace(response.redirect);
                            } else {
                                // TODO: also show link for user to click
                                window.location.href = response.redirect;
                            }
                            return;
                        }
                        console.error('signup error: server redirect response missing redirect url');
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                        break;
                    case 'display_name':
                        // prompt user to enter display name
                        this.step = 'display_name';
                        this.$router.replace({
                            name: 'signup',
                            query: {
                                ...this.$route.query,
                                email: this.username,
                                step: 'display_name',
                                t: Date.now(),
                            },
                        });
                        this.$nextTick(() => {
                            setTimeout(() => { this.activate('displayNameInput'); }, 1);
                        });
                        break;
                    case 'setup_loginshield':
                        // user record was created but LoginShield not set up yet
                        this.step = 'setup_loginshield';
                        break;
                    case 'authenticated':
                        await this.$store.dispatch('refresh');
                        // user is already authenticated with same email address, so complete enterprise setup steps
                        this.step = 'notice';
                        this.$router.replace({ name: 'signup', query: { ...this.$route.query, step: 'notice', t: Date.now() } });
                        break;
                    case 'setup_required':
                        // user record was created, but authentication not set up yet
                        // TODO: check if user has loginshield set up yet; if not redirect to that setup; if already set up, check if user has an app linked; if not, tell user about the app and hsow download links
                        await this.$store.dispatch('refresh');
                        this.setup();
                        break;
                    case 'login_required':
                        /*
                        // email is verified but user not authenticated here; redirect to login
                        this.$router.push({ name: 'login' });
                        */
                        this.registered = true;
                        this.step = 'verify_email';
                        break;
                    case 'error':
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                        break;
                    default:
                        console.error(`signup error: unexpected status from server: ${JSON.stringify(response.status)}`);
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    console.error('signup error: server response missing status');
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                }
            } catch (err) {
                console.error('failed to sign up', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.resetErrors();
                        this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { signup: false });
                this.isViewReady = true;
            }
        },
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        switchEnterprise() {
            this.resetErrors();
            this.step = 'start';
            this.$router.replace({ name: 'signup', query: { ...this.$route.query, step: 'start', t: Date.now() } });
            this.$nextTick(() => {
                setTimeout(() => { this.activate('usernameInput'); }, 1);
            });
        },
        setup() {
            if (this.product) {
                this.$router.push({ name: 'profile-setup', query: { product: this.product } });
            } else {
                this.$router.push({ name: 'profile-setup' });
            }
        },
        async loadInteraction(interactionId) {
            try {
                this.$store.commit('loading', { loadInteraction: true });
                const response = await this.$client.main().interaction.get(interactionId);
                console.log(`loadInteraction response: ${JSON.stringify(response)}`);
                const { type, /* next, */ state = {} } = response;
                console.log(`signup.vue: loadInteraction: interaction id: ${interactionId} type: ${type} state: ${JSON.stringify(state)}`);
                // we only handle signup interactions here; for anything else redirect user to the interaction dispatcher
                if (type !== IA_SIGNUP) {
                    this.$router.replace({ name: 'interaction', query: { i: interactionId } });
                    return;
                }
                if (state.product) {
                    this.product = state.product;
                }
                // if we have a verified email address, continue to next step
                if (state.email) {
                    this.username = state.email;
                    this.signup();
                    return;
                }
                // the input email address is whatever the user entered here before they were redirected to cryptium id; since they didn't return with a verified email address, we can at least show them the same thing they entered before being redirected
                if (state.input?.email) {
                    // TODO: show a message indicating that the email wasn't verified, to explain why we're asking for it again
                    this.username = state.input.email;
                    return;
                }
                this.username = this.$route.query.email ?? '';
            } catch (err) {
                console.error('failed to activate token', err);
            } finally {
                this.$store.commit('loading', { loadInteraction: false });
            }
        },
    },
    mounted() {
        // if user arrives with `product=dynamic-dns|essential|business|enterprise`,
        // pass this value along to the next step; the `product` value will be validated
        // at Unicorn Springs and if it's invalid user will see a choice of products to
        // select at that time; here we do just a valid-characters check on the value
        if (this.$route.query.product && isValidProductLookupKey(this.$route.query.product)) {
            this.product = this.$route.query.product;
        }

        // if user arrives with `email` and `step=verify_email`, call signup to check
        // the email address, if it's not verified we will redirect to EtherLink, and
        // eventually return here with a verified address or cancel the interaction
        if (this.$route.query.step === 'verify_email' && this.$route.query.email) {
            this.step = 'verify_email';
            this.username = this.$route.query.email;
            this.signup();
            return;
        }

        // if user arrives with `email` and `step=display_name`, show the email address and
        // ask user for their display name
        if (this.$route.query.step === 'display_name' && this.$route.query.email) {
            this.step = 'display_name';
            this.username = this.$route.query.email;
            this.$nextTick(() => {
                setTimeout(() => { this.activate('displayNameInput'); }, 1);
            });
            return;
        }

        // the first time user arrives on this page, there would not be an interaction id in the query,
        // but there could be an email address to pre-fill;
        // the second time the user arrives on this page (after email verification is completed), there
        // would be an interaction id also;
        // we only auto-submit if there's an interaction id meaning the user got here via one of our own links
        this.interactionId = this.$route.query.i;
        if (this.interactionId) {
            this.loadInteraction(this.interactionId);
            return;
        }

        this.isViewReady = true;
        this.$nextTick(() => {
            setTimeout(() => { this.activate('usernameInput'); }, 1);
        });
        // TODO: load brand info if we have this.$route.query.brand  (is probably etherlink brand for signup)
        // if (this.username) {
        //     this.search();
        // }
    },
};
</script>
